import React from "react";
import "./App.css";
import Navigation from "./components/navigation/Navigation";
import Display from "./components/display/Display";
import Loading from "./components/loading/Loading";

const Services = React.lazy(() => import("./components/services/Services"));
const Gallery = React.lazy(() => import("./components/gallery/Gallery"));
const Contact = React.lazy(() => import("./components/contact/Contact"));
const Footer = React.lazy(() => import("./components/footer/Footer"));

function App() {
  return (
    <div id="App">
      <Navigation />
      <main>
        <Display />
        <React.Suspense fallback={<Loading />}>
          <Services />
          <Gallery />
          <Contact />
          <Footer />
        </React.Suspense>
      </main>
    </div>
  );
}

export default App;
