import React from "react";
import "./Loading.css";

export default () => {
  return (
    <div id="loading">
      <div className="lds-ring">
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  );
};
